import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const LanguageMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "LanguageLanguageNavigationTitle",
        path: "/admin/language",
        page: "Language",
        subpage: "Language",
      },
      {
        id: "LanguageTranslationNavigationTitle",
        path: "/admin/language_translation",
        page: "Language",
        subpage: "Translation",
      },
    ]}
  />
);

export default LanguageMenu;
