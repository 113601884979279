import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import {
  InternationalizationDto,
  InternationalizationKey,
  InternationalizationsClient,
  SupportedLanguageClient,
  UpdateInternationalizationCommand,
} from "@api";
import { Download, showError } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import LanguageMenu from "@components/Admin/Language/LanguageMenu";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import { RcFile } from "antd/lib/upload";
import ContentKeyValueTable from "@components/Admin/Language/ContentKeyValueTable";

const defaultOrderBy = "key";
const defaultSortOrder = "ascend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Language", "Translation");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };

  const internationalizationsClient = new InternationalizationsClient(
    undefined,
    allLanguageFetch
  );
  const supportedLanguageClient = new SupportedLanguageClient();

  const [state, setState] = useState({
    items: [] as InternationalizationDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Translation")) return;

    setState({
      ...state,
      loading: true,
    });
    try {
      const supportedLocales =
        await supportedLanguageClient.getSupportedLanguages(undefined);
      const res =
        await internationalizationsClient.getInternationalizationsWithPagination(
          undefined,
          _keywords ?? keywords,
          params.pagination.current,
          params.pagination.pageSize,
          capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
          (params.sortOrder ?? defaultSortOrder) == "ascend"
        );

      const baseContent: { [key: string]: string } = {};
      supportedLocales
        .map((v) => v.id as string)
        .forEach((element) => {
          baseContent[element] = "";
        });

      const temp = res.items?.slice();
      temp?.forEach((element) => {
        element.content = { ...baseContent, ...element.content };
      });

      setState({
        ...state,
        items: temp || ([] as InternationalizationDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleDownloadTranslation() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await internationalizationsClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadTranslation(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await internationalizationsClient.uploadContents({
        data: f,
        fileName: f.name,
      });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleEditTranslation(parent: any, target: any, data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );

    try {
      if (!target || !parent) {
        throw "Error";
      }

      await internationalizationsClient.update(
        new UpdateInternationalizationCommand({
          key: parent.key,
          content: data.value,
          languageId: target.key,
        })
      );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
      await fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns: any = [
    {
      // title: "Key",
      title: <FormattedMessage id="TranslationTableHeaderKey" />,
      dataIndex: "key",
      key: "key",
      sorter: true,
      defaultSortOrder,
      render: (key: any) => {
        return <>{InternationalizationKey[key]}</>;
      },
    },
    // {
    //   title: "Content",
    //   dataIndex: "content",
    //   key: "content",
    //   sorter: true,
    // },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="LanguageTranslationNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <LanguageMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Download, "Translation") ? (
                <Button type="primary" onClick={handleDownloadTranslation}>
                  <FormattedMessage id="LanguageTranslationContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "Translation") ? (
                <Upload action={handleUploadTranslation}>
                  <Button type="primary">
                    <FormattedMessage id="LanguageTranslationContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.key}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender: (record) => (
                <ContentKeyValueTable
                  content={record.content}
                  parent={record}
                  handleAction={handleEditTranslation}
                  editable={auth.can(ACTION.Edit, "Translation")}
                />
              ),
              rowExpandable: () => true,
            }}
          />
        </Space>
      </Card>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
